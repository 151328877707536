export default [
  {
    id: "cus_34Dcj3D9VoHMQ0udYR",
    address: "",
    allow_pdf: true,
    allow_xml: true,
    email: "",
    metadata: {},
    name: "TRIBI O CAICEDO WANDA DALIANA",
    phone: null,
    state: "",
    business_activity: "",
    created: "2024-01-26T13:53:21.041075-03:00",
    updated: "2024-01-26T13:53:21.041099-03:00",
    taxpayer: {
      tax_id: "003943052",
      name: "TRIBI O CAICEDO WANDA DALIANA",
      address: "",
      state: "",
      phone: null,
      email: "",
      irs_settings: {},
      country: "PE"
    }
  },
  {
    id: "cus_0pzR9041RZtE6KorR0",
    address: "",
    allow_pdf: true,
    allow_xml: true,
    email: "",
    metadata: {},
    name: "CABANA SALAZAR ADA ESPERANZA",
    phone: null,
    state: "",
    business_activity: "",
    created: "2024-01-26T13:51:54.098474-03:00",
    updated: "2024-01-26T13:51:54.098498-03:00",
    taxpayer: {
      tax_id: "08244272",
      name: "CABANA SALAZAR ADA ESPERANZA",
      address: "",
      state: "",
      phone: null,
      email: "",
      irs_settings: {},
      country: "PE"
    }
  },
  {
    id: "cus_xBJaeGgAICYEALLd0c",
    address: "",
    allow_pdf: true,
    allow_xml: true,
    email: "",
    metadata: {},
    name: "GUTIERREZ RODRIGUEZ LIZ CLAUDIA",
    phone: null,
    state: "",
    business_activity: "",
    created: "2024-01-26T13:40:22.949541-03:00",
    updated: "2024-01-26T13:40:22.949567-03:00",
    taxpayer: {
      tax_id: "40974561",
      name: "GUTIERREZ RODRIGUEZ LIZ CLAUDIA",
      address: "",
      state: "",
      phone: null,
      email: "",
      irs_settings: {},
      country: "PE"
    }
  },
  {
    id: "cus_n2BdbI7snpOwR5JaKr",
    address: "",
    allow_pdf: true,
    allow_xml: true,
    email: "",
    metadata: {},
    name: "GUTIERREZ PEÑA SEBASTIAN ANDRES",
    phone: null,
    state: "",
    business_activity: "",
    created: "2024-01-26T13:02:19.374297-03:00",
    updated: "2024-01-26T13:02:19.374321-03:00",
    taxpayer: {
      tax_id: "77768168",
      name: "GUTIERREZ PEÑA SEBASTIAN ANDRES",
      address: "",
      state: "",
      phone: null,
      email: "",
      irs_settings: {},
      country: "PE"
    }
  },
  {
    id: "cus_WqDKxVkU75j4kYvM3a",
    address: "",
    allow_pdf: true,
    allow_xml: true,
    email: "",
    metadata: {},
    name: "GUTIERREZ PEÑA RODRIGO MARTIN",
    phone: null,
    state: "",
    business_activity: "",
    created: "2024-01-26T13:01:36.316192-03:00",
    updated: "2024-01-26T13:01:36.316213-03:00",
    taxpayer: {
      tax_id: "79646756",
      name: "GUTIERREZ PEÑA RODRIGO MARTIN",
      address: "",
      state: "",
      phone: null,
      email: "",
      irs_settings: {},
      country: "PE"
    }
  },
  {
    id: "cus_KN2RC2xiHgrFeWEfo6",
    address: "",
    allow_pdf: true,
    allow_xml: true,
    email: "",
    metadata: {},
    name: "PORRAS JARAMILLO ORIETTA NADHIA",
    phone: null,
    state: "",
    business_activity: "",
    created: "2024-01-26T13:00:05.784084-03:00",
    updated: "2024-01-26T13:00:05.784106-03:00",
    taxpayer: {
      tax_id: "74847831",
      name: "PORRAS JARAMILLO ORIETTA NADHIA",
      address: "",
      state: "",
      phone: null,
      email: "",
      irs_settings: {},
      country: "PE"
    }
  },
  {
    id: "cus_0DjLjsxAig8iCppjmW",
    address: "",
    allow_pdf: true,
    allow_xml: true,
    email: "",
    metadata: {},
    name: "OSORIO ANGULO ANGELA AMANDA",
    phone: null,
    state: "",
    business_activity: "",
    created: "2024-01-26T12:57:57.029417-03:00",
    updated: "2024-01-26T12:57:57.029441-03:00",
    taxpayer: {
      tax_id: "44073691",
      name: "OSORIO ANGULO ANGELA AMANDA",
      address: "",
      state: "",
      phone: null,
      email: "",
      irs_settings: {},
      country: "PE"
    }
  },
  {
    id: "cus_Yz1bzppad3iv3a05FO",
    address: "",
    allow_pdf: true,
    allow_xml: true,
    email: "",
    metadata: {},
    name: "MONASI QUERZOLA ALFREDO",
    phone: null,
    state: "",
    business_activity: "",
    created: "2024-01-26T12:50:05.394330-03:00",
    updated: "2024-01-26T12:50:05.394460-03:00",
    taxpayer: {
      tax_id: "40868698",
      name: "MONASI QUERZOLA ALFREDO",
      address: "",
      state: "",
      phone: null,
      email: "",
      irs_settings: {},
      country: "PE"
    }
  },
  {
    id: "cus_gQO5RhMmirGsYES1S1",
    address: "",
    allow_pdf: true,
    allow_xml: true,
    email: "",
    metadata: {},
    name: "STAGNARO HURTADO FLOR DE MARIA TERESA",
    phone: null,
    state: "",
    business_activity: "",
    created: "2024-01-26T12:49:34.924066-03:00",
    updated: "2024-01-26T12:49:34.924089-03:00",
    taxpayer: {
      tax_id: "25665845",
      name: "STAGNARO HURTADO FLOR DE MARIA TERESA",
      address: "",
      state: "",
      phone: null,
      email: "",
      irs_settings: {},
      country: "PE"
    }
  },
  {
    id: "cus_nndQvtlhnT0WajIpFO",
    address: "",
    allow_pdf: true,
    allow_xml: true,
    email: "",
    metadata: {},
    name: "REYES MILLONES YESSICA DEL MILAGRO",
    phone: null,
    state: "",
    business_activity: "",
    created: "2024-01-26T12:41:10.273432-03:00",
    updated: "2024-01-26T12:41:10.273456-03:00",
    taxpayer: {
      tax_id: "10635088",
      name: "REYES MILLONES YESSICA DEL MILAGRO",
      address: "",
      state: "",
      phone: null,
      email: "",
      irs_settings: {},
      country: "PE"
    }
  },
  {
    id: "cus_IsKhDFOfeDIXSk6d0X",
    address: "",
    allow_pdf: true,
    allow_xml: true,
    email: "",
    metadata: {},
    name: "CASTAÑEDA BENAVIDES MARIANELLA",
    phone: null,
    state: "",
    business_activity: "",
    created: "2024-01-26T12:30:52.904638-03:00",
    updated: "2024-01-26T12:30:52.904668-03:00",
    taxpayer: {
      tax_id: "10288894",
      name: "CASTAÑEDA BENAVIDES MARIANELLA",
      address: "",
      state: "",
      phone: null,
      email: "",
      irs_settings: {},
      country: "PE"
    }
  },
  {
    id: "cus_DXiuzyRi1NvSMb6vhi",
    address: "",
    allow_pdf: true,
    allow_xml: true,
    email: "",
    metadata: {},
    name: "PUSE DEL CAMPO LENIN OSMAR",
    phone: null,
    state: "",
    business_activity: "",
    created: "2024-01-26T12:16:45.662072-03:00",
    updated: "2024-01-26T12:16:45.662090-03:00",
    taxpayer: {
      tax_id: "43275612",
      name: "PUSE DEL CAMPO LENIN OSMAR",
      address: "",
      state: "",
      phone: null,
      email: "",
      irs_settings: {},
      country: "PE"
    }
  },
  {
    id: "cus_e3dJV45F3JWP1laAoL",
    address: "",
    allow_pdf: true,
    allow_xml: true,
    email: "",
    metadata: {},
    name: "ORTEGA ESPINOZA ALEX FERNANDO",
    phone: null,
    state: "",
    business_activity: "",
    created: "2024-01-26T12:01:35.423601-03:00",
    updated: "2024-01-26T12:01:35.423631-03:00",
    taxpayer: {
      tax_id: "10220464",
      name: "ORTEGA ESPINOZA ALEX FERNANDO",
      address: "",
      state: "",
      phone: null,
      email: "",
      irs_settings: {},
      country: "PE"
    }
  },
  {
    id: "cus_l8pTvyOXV9VCgIBfDj",
    address: "",
    allow_pdf: true,
    allow_xml: true,
    email: "",
    metadata: {},
    name: "BERROSPI BACILIO BRICEIDA CRISTINA",
    phone: null,
    state: "",
    business_activity: "",
    created: "2024-01-26T11:57:46.580179-03:00",
    updated: "2024-01-26T11:57:46.580203-03:00",
    taxpayer: {
      tax_id: "71388209",
      name: "BERROSPI BACILIO BRICEIDA CRISTINA",
      address: "",
      state: "",
      phone: null,
      email: "",
      irs_settings: {},
      country: "PE"
    }
  },
  {
    id: "cus_xCU6lfEvaHlOPoyBxs",
    address: "",
    allow_pdf: true,
    allow_xml: true,
    email: "",
    metadata: {},
    name: "NIKKEIDENT SOCIEDAD ANONIMA CERRADA-NIKKEIDENT S.A.C.",
    phone: null,
    state: "",
    business_activity: "",
    created: "2024-01-26T11:55:17.080363-03:00",
    updated: "2024-01-26T11:55:17.080386-03:00",
    taxpayer: {
      tax_id: "20521306034",
      name: "NIKKEIDENT SOCIEDAD ANONIMA CERRADA-NIKKEIDENT S.A.C.",
      address: "",
      state: "",
      phone: null,
      email: "",
      irs_settings: {},
      country: "PE"
    }
  },
  {
    id: "cus_qSw1oZhlJNzduRRq7B",
    address: "",
    allow_pdf: true,
    allow_xml: true,
    email: "",
    metadata: {},
    name: "GELDRES DE AUCAHUASI LUZ MERCEDES",
    phone: null,
    state: "",
    business_activity: "",
    created: "2024-01-26T11:55:14.049252-03:00",
    updated: "2024-01-26T11:55:14.049273-03:00",
    taxpayer: {
      tax_id: "06017461",
      name: "GELDRES DE AUCAHUASI LUZ MERCEDES",
      address: "",
      state: "",
      phone: null,
      email: "",
      irs_settings: {},
      country: "PE"
    }
  },
  {
    id: "cus_TvBv9YJyviiQteROOG",
    address: "",
    allow_pdf: true,
    allow_xml: true,
    email: "",
    metadata: {},
    name: "SERVICIOS MEDICOS REMA E.I.R.L. SM REMA E.I.R.L.",
    phone: null,
    state: "",
    business_activity: "",
    created: "2024-01-26T11:55:07.568157-03:00",
    updated: "2024-01-26T11:55:07.568187-03:00",
    taxpayer: {
      tax_id: "20604419396",
      name: "SERVICIOS MEDICOS REMA E.I.R.L. SM REMA E.I.R.L.",
      address: "",
      state: "",
      phone: null,
      email: "",
      irs_settings: {},
      country: "PE"
    }
  },
  {
    id: "cus_aNE3Yv9FcFDBTYrAze",
    address: "",
    allow_pdf: true,
    allow_xml: true,
    email: "",
    metadata: {},
    name: "MORON MARTINEZ JULIA ELVIRA",
    phone: null,
    state: "",
    business_activity: "",
    created: "2024-01-26T11:55:06.091919-03:00",
    updated: "2024-01-26T11:55:06.091954-03:00",
    taxpayer: {
      tax_id: "08702058",
      name: "MORON MARTINEZ JULIA ELVIRA",
      address: "",
      state: "",
      phone: null,
      email: "",
      irs_settings: {},
      country: "PE"
    }
  },
  {
    id: "cus_uJFPPTDojbYiCu4k0i",
    address: "",
    allow_pdf: true,
    allow_xml: true,
    email: "",
    metadata: {},
    name: "JACOBO GONZALES CLAUDIA SILVANA",
    phone: null,
    state: "",
    business_activity: "",
    created: "2024-01-26T11:54:22.014218-03:00",
    updated: "2024-01-26T11:54:22.014242-03:00",
    taxpayer: {
      tax_id: "46493514",
      name: "JACOBO GONZALES CLAUDIA SILVANA",
      address: "",
      state: "",
      phone: null,
      email: "",
      irs_settings: {},
      country: "PE"
    }
  },
  {
    id: "cus_5AgGheowZIPz1COz7i",
    address: "",
    allow_pdf: true,
    allow_xml: true,
    email: "",
    metadata: {},
    name: "CAMACHO CARRANZA LUIS ALBERTO",
    phone: null,
    state: "",
    business_activity: "",
    created: "2024-01-26T11:46:16.484203-03:00",
    updated: "2024-01-26T11:46:16.484226-03:00",
    taxpayer: {
      tax_id: "40829129",
      name: "CAMACHO CARRANZA LUIS ALBERTO",
      address: "",
      state: "",
      phone: null,
      email: "",
      irs_settings: {},
      country: "PE"
    }
  }
]