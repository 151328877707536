<template>
  <v-row no-gutters>
   <v-col cols="12" class="pa-0">
     <skeleton-list v-if="$store.state.customers.retrieveLoader" />
     <template v-else>
      <v-empty-state v-if="!customersList.length || Object.keys($route?.query).length === 0" type="suppliers" id="clientes" :hiddenDescription="hiddenBtnEmpty">
          <template v-slot:actions>
            <template v-if="!$route.query.ordering && hiddenBtnEmpty">
              <v-btn @click="$emit('activeBtnFilter')" color="primary" :ripple="false" :width="153"><v-icon class="mr-2">mdi-filter-plus</v-icon>Filtrar</v-btn>
              <v-btn class="ml-2" @click="setSort('-date')" outlined :ripple="false" width="153"><v-icon class="mr-2">mdi-text-search-variant</v-icon>Últimos registros</v-btn>
            </template>
          </template>
        </v-empty-state>
       <template v-else>
         <v-data-table
            :class="{'fixed-table' : $store.state.base.isExpandNavigationDrawer}"
            :headers="headers"
            :items="customersList"
            :items-per-page="pagination"
            mobile-breakpoint
            hide-default-header
            disable-sort
            hide-default-footer
            @click:row="(customersList) => {goTo(customersList.id)}"
            style="cursor: pointer"
            fixed-header
            :height="heightTable"
          >
           <!-- header -->
           <template v-slot:[`header`]="{ props: { headers } }">
             <VTableHeaders :headers="headers" @sort="setSort" />
             <v-divider style="position: absolute;  z-index: 2; margin-top: -6px; min-width: 100%; margin-left: -5px" />
           </template>
           <!-- end header -->
           <template v-slot:[`item.name`]="{item}">
            <span class="body-2 d-inline-block text-truncate" v-if="(item || {}).name" style="max-width: 100%;">{{(item || {}).name}}</span>
            <span class="d-block body-2 mb-1 text--disabled font-italic" v-else>Sin especificar</span>
            <span class="caption fontDraw--text d-block" v-if="((item || {}).taxpayer || {}).tax_id">{{$t('generals.RUT')}} {{((item || {}).taxpayer || {}).tax_id}}</span>
            <span class="d-block body-2 text--disabled font-italic" v-else>Sin especificar</span>
           </template>
           <template v-slot:[`item.business_activity`]="{item}">
            <span class="body-2 d-inline-block text-truncate" style="max-width: 100%;" v-if="item.business_activity">{{item.business_activity}}</span>
            <span class="d-block body-2 text--disabled font-italic" v-else>Sin especificar</span>
           </template>
           <template v-slot:[`item.email`]="{item}">
             <span v-if="item.email">{{item.email}}</span>
             <span class="d-block body-2 text--disabled font-italic" v-else>Sin especificar</span>
           </template>
           <template v-slot:[`item.address`]="{item}">
            <span class="body-2 d-inline-block text-truncate" style="max-width: 100%;" v-if="item.address">{{item.address}}</span>
            <span class="d-block body-2 text--disabled font-italic" v-else>Sin especificar</span>
           </template>
           <template v-slot:[`item.state`]="{item}">
             <span>{{nameState(item.state) || item.state}}</span>
           </template>
         </v-data-table>
         <VMainPagination v-if="showPagination || showAll" :count="count" />
       </template>
     </template>
   </v-col>
 </v-row>
</template>
<script>
import { mapState } from 'vuex'
import customers from '@/data/customerList' // example
import SkeletonList from '@/components/commons/skeletonLoader/SkeletonList'
import VEmptyState from '@/components/commons/VEmptyState'
import VTableHeaders from '@/components/VTable/VTableHeaders'
import ListViewMixin from '@/mixins/ListViewMixin'
import VMainPagination from '@/components/commons/VMainPagination'

export default {
 components: {
   VTableHeaders,
   VEmptyState,
   SkeletonList,
   VMainPagination
 },
 mixins: [
   ListViewMixin
 ],
 data: () => ({
  customersList: customers,
   current: {},
   env: process.env?.VUE_APP_MODE ?? 'ax',
   headers: [
     { text: 'Cliente', value: 'name', sortable: true },
     { text: 'Actividad económica', value: 'business_activity', sortable: true },
     { text: 'Correo de facturación', value: 'email', sortable: true },
     { text: 'Dirección', value: 'address' },
     { text: 'Comuna', value: 'state', width: 100 }
    ]
 }),
 computed: {
     ...mapState({
     count: state => state.customers.customersCount
     // customersList: state => state.customers.customersList
   }),
   nameState () {
     return (item = '') => {
       return this.$store.getters['base/currentStates']?.find(state => (state.id === item))?.name ?? ''
     }
   },
   heightTable () {
    if (this.customersList.length <= 16) return 'auto'
    // if (this.$vuetify.breakpoint.height <= 1200 && this.customersList.length >= 16 && this.customersList.length <= 20) return 'calc(100vh - 210px)'
    // if (this.customersList.length > 20) return 'calc(100vh - 165px)'
    // if (this.customersList.length < 16) return 'auto'
    return 'calc(100vh - 205px)'
  },
  hiddenBtnEmpty () {
    if (!Object.keys(this.$route.query ?? {}).length) return true
    else if (!this.customersList.length) return false
    return false
  }
 },
 methods: {
  goTo (id = null) {
    this.$store.commit('base/SET_QUERY', this.$route.query)
    this.$router.push({ name: 'CustomerRetrieveUpdate', params: { id: id }})
  }
 }
}
</script>